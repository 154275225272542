<template>
  <Loader :value="promises.fetch" class="contact-page">
    <Blocks :blocks="config" />
  </Loader>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BrowserApiMixin from '@/mixins/browser-api'

// pagebuilder
import { Blocks } from '@kvass/pagebuilder'
import config from '@/config/contact.js'

export default {
  mixins: [BrowserApiMixin('contact')],
  computed: {
    ...mapState('Page', ['promises', 'item']),
    config() {
      return config.call(this)
    },
  },
  methods: {
    ...mapActions('Page', ['fetch']),
  },
  created() {
    this.fetch()
  },
  metaInfo() {
    return {
      title: this.$t('getInTouch'),
    }
  },
  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.contact-page {
  h1 {
    margin-top: 0;
  }

  .lead__section {
    .kpb-section {
      &__container {
        max-width: 700px;

        .lead-title {
          h2 {
            margin: 0;
          }
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          margin-bottom: 1.5rem;
        }

        .lead__form-actions button {
          width: 100%;
        }
      }
    }
  }
}
</style>
