import { TransformTag } from '@/utils'

export default function() {
  const get = path => this.$path(path, this.$store.state)
  return [
    {
      component: 'Section',
      condition: get('Page.item.customFields.contacts.length'),
      props: {
        class: 'contacts-section',
        width: 'large',
        style: 'padding-block: 3rem 4rem; background-color: var(--custom-background-color);',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              condition: get('Page.item.customFields.contact-content'),

              textAlign: 'center',
              style: 'padding-bottom: 4rem; max-width: 700px; margin: 0 auto;',
            },
            data: {
              content: TransformTag(get('Page.item.customFields.contact-content')),
            },
          },
          {
            component: 'Portal',
            variant: 'contact',
            data: {
              content: (get('Page.item.customFields.contacts') || [])
                .filter(c => c.avatar)
                .map(contact => {
                  return {
                    ...contact,
                    media: {
                      cover: [contact.avatar],
                    },
                  }
                }),
            },
            props: {
              theme: 'thumbnail',
              disableNav: true,
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'small',
        class: 'lead__section',
      },

      blocks: {
        default: [
          {
            component: 'Lead',

            data: {
              type: 'page',
              reference: { onModel: 'Page', ref: get('Page.item.id') },
              fields: ['!upsell', '!title'],
              defaultData: {},
              actionLabel: get('Page.item.customFields.lead.action-label') || this.$t('send'),
            },
            blocks: {
              top: [
                {
                  component: 'Text',
                  props: {
                    class: 'lead-title',
                  },
                  data: {
                    content: `<h2>${get('Page.item.customFields.lead.title') ||
                      this.$tc('getInTouch')}</h2> <span>${get(
                      'Page.item.customFields.lead.subtitle',
                    ) || ''}</span>`,
                  },
                },
              ],
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'full',
        style: '--section-padding-block: 0rem',
      },
      blocks: {
        default: [
          {
            component: 'Map',
            data: {
              ...get('Root.item.address'),
            },
          },
        ],
      },
    },
  ]
}
